import React, { useRef, useEffect, Suspense } from "react"
import Layout from "../components/layout"
import Footer from '../components/layout/footer'
// graphics
import Scene1 from "../components/layout/scenes/scene1"


import { Parallax, ParallaxLayer } from "@react-spring/parallax"
import useWindowSize from "../hooks/useWindowSize"
import Scene5 from '../components/layout/scenes/scene5'



const Scene2 = React.lazy(() => import('../components/layout/scenes/scene2'))
const Scene3 = React.lazy(() => import('../components/layout/scenes/scene3'))
const Scene4 = React.lazy(() => import('../components/layout/scenes/scene4'))


export const Head = () => (
  <>
    <title>Homepage</title>
    <meta name="description" content="Homepage" />
  </>
)


const MainPage = props => {
  const parallax = useRef()
  const { width, height } = useWindowSize()
  //const pages = useState(width < 960 ? 1 : (width > 1600 ? 5 : 3))

  const handleScroll = () => {
    if (parallax.current) {
      const header = document.getElementById("header")
      if (parallax.current.current > 80) {
        header.classList.add("scroll")
      } else {
        header.classList.remove("scroll")
      }
    }
  }

  useEffect(() => {
    const container = document.querySelector(".parallax-container")
    container.addEventListener("scroll", handleScroll)
    return () => {
      container.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return (
    <Layout variant="sidebar">
      <Parallax ref={parallax} className="parallax-container">
        <Scene1 ParallaxLayer={ParallaxLayer} />
        <Suspense fallback="loading">
          <Scene2 windowHeight={height} windowWidth={width} />
        </Suspense>
        <Suspense fallback="loading">
          <Scene3 windowHeight={height} windowWidth={width} />
        </Suspense>
        <Suspense fallback="loading">
          <Scene4 windowHeight={height} windowWidth={width} />
        </Suspense>
          <Scene5 windowHeight={height} windowWidth={width} />
        <Footer />
      </Parallax>
    </Layout>
  )
}
export default MainPage
