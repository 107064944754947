import React from "react"

import OctoMobile from "../../images/homepage/scene5/octopus.svg"
import OctoDesktop from "../../images/homepage/scene5/octopus-desktop.svg"
import Bubbles from "./bubbles"
import "./octopus.css"

function Octopus ({ windowWidth, windowHeight }) {
  const isMobile = windowWidth < 930 ? true : false
  const Octo = isMobile ? OctoMobile : OctoDesktop
  return (
    <>
      <Octo
        style={{
          width: "100%",
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        }}
      />
      <Bubbles
        style={{
          top: "0%",
          left: "40%",
          width: "60%",
          overflow: 'visible',
        }}
        number={350}
        size={5}
        height={'750px'}
      />
    </>
  )
}

export default Octopus
