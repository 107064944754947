import React from 'react'
import { Box, Flex, Text, Heading, useThemeUI } from 'theme-ui'

import { StaticImage } from 'gatsby-plugin-image'
import './scene1.css'

// Graphics
import Sky from '../../../components/graphics/sky'
import Mountains from '../../../components/graphics/mountains'
import Trees from '../../../components/graphics/trees'

// Animations
import Stars from '../../../components/graphics/star'
import Clouds from '../../../components/graphics/cloud'
import Sun from '../../../components/graphics/sun'
import Birds from '../../../components/graphics/birds'

import useWindowSize from '../../../hooks/useWindowSize'

function SkyScene({ ParallaxLayer }) {
  const context = useThemeUI()
  const { colorMode } = context
  const { width, height } = useWindowSize()
  const parallaxHeight = width < 930 ? '590px' : '700px'
  return (
    <>
      <ParallaxLayer
        offset={0}
        speed={0}
        factor={1}
        className="parallaxScene1"
        style={{
          display: 'block',
          position: 'relative',
          height: parallaxHeight,
        }}
      >
        <Box sx={{ height: ['600px', '100vh'], display: 'block' }}>
          <Sky>
            {colorMode === 'dark' && <Stars />}
            <Birds />
            {colorMode !== 'dark' && (
              <Box className="clouds-far">
                <StaticImage
                  src="../../../images/homepage/scene1/clouds-far.png"
                  alt="distant clouds in sky"
                  placeholder="blurred"
                  layout="fixed"
                  width={1650}
                  height={210}
                  fit="outside"
                />
              </Box>
            )}
            <Clouds />
            <Sun />
            <Mountains
              width={width}
              height={height}
              ParallaxLayer={ParallaxLayer}
            />
            <Trees
              width={width}
              height={height}
              ParallaxLayer={ParallaxLayer}
            />
            <ParallaxLayer
              offset={0}
              speed={-0.3}
              style={{
                zIndex: -1,
              }}
            >
              <Flex
                sx={{
                  height: ['450px', '500px', '500px', '500px'],
                  padding: '0',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'start',
                  marginLeft: ['3em', '4em', '7em', '10em'],
                  color: 'white',
                  zIndex: 12,
                  '@media screen and (max-width: 500px)': {
                    marginLeft: '2em',
              },
                }}
              >
                <Flex
                  sx={{
                    justifyContent: 'center',
                    flexDirection: 'column',
                    width: 'fit-content',
                    textAlign: 'left',
                  }}
                >
                  <Heading variant="xl" className="faux-font heading">
                    Expand Your <br /> Horizons
                  </Heading>
                  <Heading sx={{ fontSize: '2.3em', '@media screen and (max-width: 500px)': {
                fontSize: '1.2em',
              }, }}>
                    <Text variant="smallHeading">with</Text> Deb Weir
                    <Text variant="smallHeading">, PhD, LCMHCS</Text>
                  </Heading>
                </Flex>
              </Flex>
            </ParallaxLayer>
          </Sky>
        </Box>
      </ParallaxLayer>
    </>
  )
}

export default SkyScene
