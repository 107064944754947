import React from 'react'
import { animated } from '@react-spring/web'
import { Box, useThemeUI } from 'theme-ui'
import './trees.css'

import BottomTrees from '../../images/homepage/scene1/bot-trees.svg'
import MidBottomTrees from '../../images/homepage/scene1/mid-bot-trees.svg'
import MidTopTrees from '../../images/homepage/scene1/mid-top-trees.svg'
import TopTrees from '../../images/homepage/scene1/top-trees.svg'

function Trees({ width, height, ParallaxLayer }) {
  const context = useThemeUI()
  const { colorMode } = context
  const isDark = colorMode === 'dark'
  return (
    <animated.div>
      <ParallaxLayer offset={0} speed={-0.05} style={{ height: 'inherit' }}>
        <Box className="trees treesBack">
          <TopTrees className={isDark ? 'trees dark' : 'trees light'} />
        </Box>
      </ParallaxLayer>
      <ParallaxLayer offset={0} speed={-0.03} style={{ height: 'inherit' }}>
        <Box className="trees treesMidBack">
          <MidTopTrees className={isDark ? 'trees dark' : 'trees light'} />
        </Box>
      </ParallaxLayer>
      <ParallaxLayer offset={0} speed={0.05} style={{ height: 'inherit' }}>
        <Box className="trees treesMidFront">
          <MidBottomTrees className={isDark ? 'trees dark' : 'trees light'} />
        </Box>
      </ParallaxLayer>
      <ParallaxLayer offset={0} speed={0} style={{ height: 'inherit' }}>
        <Box className="trees treesFront">
          <BottomTrees className={isDark ? 'trees dark' : 'trees light'} />
        </Box>
      </ParallaxLayer>
    </animated.div>
  )
}

export default Trees
