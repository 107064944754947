import React from "react"
import { useSpring, animated } from "@react-spring/web"
import { Box } from "theme-ui"
import './fish.css'

import SchoolLeftNear from "../../images/homepage/scene5/school-left-near.svg"
import SchoolRightNear from "../../images/homepage/scene5/school-right-near.svg"
import SchoolLeftFar from "../../images/homepage/scene5/school-left-far.svg"
import SchoolRightFar from "../../images/homepage/scene5/school-right-far.svg"

import SalmonMobile from "../../images/homepage/scene3/mobile/fish.svg"
import SalmonTablet from "../../images/homepage/scene3/tablet/fish.svg"
import SalmonDesktop from "../../images/homepage/scene3/desktop/fish.svg"

function getRandomInt(min, max) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min) + min) //The maximum is exclusive and the minimum is inclusive
}

export function SalmonStream ({mobile, tablet}) {
  if (mobile) {
    return <SalmonMobile className="overlay" />
  } else if (tablet) {
    return <SalmonTablet className="overlay" />
  } else {
    return <SalmonDesktop className="overlay" />
  }
}

function Fish({ windowWidth, windowHeight }) {
  function School({ children, y, left }) {
    var startRight = getRandomInt(0, windowWidth / 2)
    var startLeft = getRandomInt(windowWidth / 2, windowWidth)
    var delay = getRandomInt(0, 20)
    const moveRight = useSpring({
      loop: {
        from: { x: -300 },
      },
      delay: delay,
      config: {
        duration: getRandomInt(24000, 32000),
      },
      to: { x: windowWidth },
      from: { x: startRight },
    })

    const moveLeft = useSpring({
      loop: {
        from: { x: windowWidth },
      },
      delay: delay,
      config: {
        duration: getRandomInt(28000, 32000),
      },
      to: { x: -100 },
      from: { x: startLeft },
    })

    return (
      <animated.div
        style={{
          position: "absolute",
          height: "60px",
          top: `${y}%`,
          ...(left ? moveLeft : moveRight),
        }}
      >
        {children}
      </animated.div>
    )
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "50%",
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      }}
    >
      <School left={true} y={50}>
        <SchoolLeftNear style={{ width: "205px" }} />
      </School>
      <School left={true} y={20}>
        <SchoolLeftFar style={{ width: "145px" }} />
      </School>
      <School y={50}>
        <SchoolRightNear style={{ width: "135px" }} />
      </School>
      <School y={20}>
        <SchoolRightFar style={{ width: "135px" }} />
      </School>
    </Box>
  )
}

export default Fish
