import React from "react"
import { animated } from "@react-spring/web"
import { Box, useThemeUI } from "theme-ui"
import './mountains.css'

import MtnBack from "../../images/homepage/scene1/mtn-top.svg"
import MtnMid from "../../images/homepage/scene1/mtn-mid.svg"
import MtnBtm from "../../images/homepage/scene1/mtn-bot.svg"

function Mountains({ width, height, ParallaxLayer }) {
  const context = useThemeUI()
  const { colorMode } = context
  const isDark = colorMode === "dark"
  return (
    <animated.div className="mountains">
      <ParallaxLayer offset={0} speed={-0.15}>
        <Box
          className="mtn mtnBack"
        >
          <MtnBack
            id="rangeTop"
            className={isDark ? "dark" : "light"}
          />
        </Box>
      </ParallaxLayer>
      <ParallaxLayer offset={0} speed={-0.1}>
        <Box
          className="mtn mtnMid"
        >
          <MtnMid
            id="rangeMid"
            className={isDark ? "dark" : "light"}
          />
        </Box>
      </ParallaxLayer>
      <ParallaxLayer offset={0} speed={-0.07}>
        <Box
          className="mtn mtnFront"
        >
          <MtnBtm id="rangeBot" className={isDark ? "dark" : "light"} />
        </Box>
      </ParallaxLayer>
    </animated.div>
  )
}

export default Mountains
