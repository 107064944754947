import React from 'react'
import { Flex, Heading, Text } from 'theme-ui'

function Feature({ heading, subheading, paragraph, styles }) {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        height: 'auto',
        width: ['90%', '50%'],
        top: '5%',
        left: ['5%', '32%'],
        alignContent: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        color: 'white',
        position: 'absolute',
        zIndex: '3',
        ...styles,
        
      }}
    > 
      <Heading variant="featureHeading" className='faux-font'>{heading}</Heading>
      <Text variant="featureSubheading">{subheading}</Text>
      <Text variant="featureParagraph">{paragraph}</Text>
    </Flex>
  )
}

export default Feature
