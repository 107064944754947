import React from 'react'
import { useSpring, animated } from '@react-spring/web'
import { useColorMode } from 'theme-ui'
import './sky.css'

function Sky({ children }) {
  const [colorMode] = useColorMode()
  const isDark = colorMode === 'dark'
  const lightGradient =
    'linear-gradient(163deg, rgba(135 114 251) 15%, rgba(203 156 254) 53%, rgba(230 189 97) 77%) 100% 60%'
  const darkGradient =
    'linear-gradient(135deg, rgb(28 21 40) 1%, rgb(35 20 86) 70%, rgb(43 37 49) 100%) 100% 60%'

  const skyGradient = useSpring({
    config: { mass: 1, tension: 10, friction: 0, duration: 300 },
    background: isDark ? darkGradient : lightGradient,
  })
  return (
    <animated.div
      className="sky"
      style={{
        background: lightGradient,
        ...skyGradient,
      }}
    >
      {children}
    </animated.div>
  )
}

export default Sky
