import React from "react"
import { useThemeUI } from "theme-ui"
import './sun.css'
import Sun from "../../images/homepage/scene1/sun.svg"

function Cycle () {
  const context = useThemeUI()
  const { colorMode } = context
  return (
    <div
      className={colorMode === "dark" ? 'sun moon' : 'sun'}
    >
      <Sun
        className="smoon"
      ></Sun>
    </div>
  )
}

export default Cycle
