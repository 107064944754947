import React from "react";
import Particles from "react-tsparticles"
import './bubble.css' 

function Bubbles ({ width, height, number, density, size, style }) {
  const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  }

  const particlesLoaded = (container) => {
    console.log(container);
  }
  return (
      <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      height={'500px'}
      width={'400px'}
      style={{ position: 'absolute', left: '60%', top: '25%' }}
      options={{
        fpsLimit: 60,
        fullScreen: { enable: false, zIndex: 1 },
        pauseOnOutsideViewport: true,
        particles: {
          number: {
            value: 100,
            density: {
              enable: true,
              value_area: 500
            }
          },
          color: {
            value: ["#149eef", "#badef9"]
          },
          shape: {
            type: "circle",
            polygon: {
              nb_sides: 4
            },
          },
          opacity: {
            value: 0.8,
            random: false,
            anim: {
              enable: true,
              speed: 1,
              opacity_min: 0,
              sync: false
            }
          },
          size: {
            value: 4,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0.5,
              sync: false
            }
          },
          line_linked: {
            enable: false,
          },
          move: {
            enable: true,
            speed: 1,
            direction: "top",
            random: false,
            straight: true,
            out_mode: "out",
          }
        },
        retina_detect: true
      }}
    />
  )
}

export default Bubbles