import React from "react"
import {
  useSpring,
  animated
} from "@react-spring/web"
import { Box } from "theme-ui"
import { getRandomInt } from "../../utils/math"

import CloudOne from "../../images/homepage/scene1/cloud-1.svg"
import CloudTwo from "../../images/homepage/scene1/cloud-2.svg"
import CloudThree from "../../images/homepage/scene1/cloud-3.svg"
import CloudFour from "../../images/homepage/scene1/cloud-4.svg"


function Cloud({children, height}) {
  const initX = getRandomInt(0, 700)

  const moveRight = useSpring({
    loop: {
      from: { x: 0 },
    },
    delay: 0,
    config: {
      duration: getRandomInt(28000, 35000),
    },
    to: { x: 1650 },
    from: { x: initX },
    reset: false
  })

  return (
    <animated.div
      style={{
        position: "absolute",
        height: "600px",
        top: `${height}px`,
        zIndex: -1,
        ...moveRight
      }}
    >
      {children}
    </animated.div>
  )
}

function Clouds() {
  return (
    <Box sx={{ width: '100%', height: '400px', position: 'absolute' }}>
      <Cloud height={'130'}>
        <CloudOne
          className="clouds"
          style={{ width: "205px" }}
        />
      </Cloud>
      <Cloud height={'230'}>
        <CloudTwo
          className="clouds"
          style={{ width: "145px" }}
        />
      </Cloud>
      <Cloud height={'330'}>
        <CloudThree
          className="clouds"
          style={{ width: "135px" }}
        />
      </Cloud>
      <Cloud height={'280'}>
        <CloudFour className="clouds" style={{ width: "135px" }} />
      </Cloud>
    </Box>
  )
}

export default Clouds
