import React, { Suspense } from "react"
import { Box, Grid } from "theme-ui"

import Scene5 from "../../../images/homepage/scene5/scene-5.png"
import Scene5Desktop from "../../../images/homepage/scene5/scene-5-desktop.png"
import Fish from "../../../components/graphics/fish"
import Feature from "../../../components/typography/feature"
import Octopus from "../../graphics/octopus"
const Bubbles = React.lazy(() => import('../../graphics/bubbles'))

function UnderwaterScene ({ windowWidth, windowHeight }) {
  const isMobile = windowWidth < 930 ? true : false
  return (
    <Grid
      gap={[0]}
      row={[1]}
      columns={[1]}
      sx={{
        marginTop: [0],
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          left: "0",
          top: "0%",
          zIndex: 0,
          overflow: 'none'
        }}
      >
        <img
          src={isMobile ? Scene5 : Scene5Desktop}
          alt="underwater background with coral and bubbles"
          style={{
            width: "100%",
            overflow: "hidden",
            position: "relative",
            display: "block",
            marginBottom: 0,
          }}
        />
        
        <Fish windowWidth={windowWidth} windowHeight={windowHeight} />
        <Octopus windowWidth={windowWidth} windowHeight={windowHeight} />
        <Box variant="fill">
          <Feature
            styles={{
              top: ["16%", "20%", "18%", "35%"],
              left: ["5%"],
              position: "absolute",
              width: ["75%", "70%", "60%", "56%"],
              '@media screen and (max-width: 500px)': {
                top: '10%',
                width: '60%'
              },
            }}
            heading="Changing patterns"
            subheading="Exploring and updating imprinted patterns of behavior"
            paragraph="I help you resolve distress and trauma from childhood and adult
                attachment wounds. This includes changing relationship patterns
                and trigger management, as well as mind-body problems and
                addiction."
          />
        </Box>

        <Suspense fallback={<div>Loading bubbles...</div>}><Bubbles /></Suspense>
      </Box>
    </Grid>
  )
}

export default UnderwaterScene
