import React from 'react'
import { useSpring, animated } from '@react-spring/web'
import { Box } from 'theme-ui'
import './birds.css'
import { getRandomInt } from '../../utils/math'

function Bird({ height, anim }) {
  var initX = getRandomInt(400, 500)

  const flyRight = useSpring({
    loop: false,
    delay: [0, 5],
    config: {
      duration: getRandomInt(25000, 36000),
    },
    to: { x: 1650 },
    from: { x: initX },
    reset: false,
    onRest: {
      x: pause => pause,
    },
  })

  return (
    <animated.div
      style={{
        position: 'absolute',
        height: '400px',
        top: `${height}px`,
        zIndex: 5,
        ...flyRight,
      }}
    >
      <div
        className={
          anim === 1 ? 'birdbox bird-anim-one' : 'birdbox bird-anim-two'
        }
      ></div>
    </animated.div>
  )
}

function Birds({ count }) {
  return (
    <Box
      sx={{ width: '100%', height: '400px', position: 'absolute', zIndex: '4' }}
    >
      <Bird height={10} anim={1} />
      <Bird height={15} anim={2} />
      <Bird height={34} anim={1} />
      <Bird height={65} anim={1} />
      <Bird height={75} anim={1} />
      <Bird height={85} anim={2} />
    </Box>
  )
}

export default Birds
